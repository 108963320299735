import React from 'react';
import NFTlist from "./NFTlist";
import Background from "../../components/background/background";
import CardFrameWeb from '../../assets/card-frame-web.png';
import AddFrame from './assets/plus.svg';

export default function NftListConfigPage() {
    return(
     <div>
         <Background ornamentConfig={[false,false,false, false]} textConfig={false} style={style}/>
         <NFTlist style={style}  CardFrame={CardFrameWeb} AddFrame={AddFrame}/>
     </div>
    )
}

const style = {
    frame: {
        height: '152px',
        width: '142px',
        content:{
            height: '124px',
            width: '124px'
        }
    },
    addFrame: {
        content:{
            height: '92px',
            width: '92px'
        }
    },
    background:{
        marginTop: '61px'
    }
}