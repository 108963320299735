import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams } from "react-router";
import {AuthContext, serverAddress} from "../../App";
import "./poster.scss";
import * as htmlToImage from "html-to-image";
import Download from './assets/download.svg';
import {bottomToast} from "../../utils/toast";
import MideaLoading from "../../components/mideaLoading/mideaLoading";

export default function Poster(){
    const { state } = useContext(AuthContext);
    const stageRef = useRef();

    const navigate = useNavigate();
    const [nft, setNft] = useState();
    const [loaded, setLoaded] = useState(false);
    const [nftLoaded, setNftLoaded] = useState(false);
    const [imgLoaded, setImgLoaded] = useState(false);

    const [tokenId, setTokenId] = useState();
    const [owner, setOwner] = useState();
    const [name, setName] = useState();
    const [publisher, setPublisher] = useState();
    const [creator, setCreator] = useState();
    const [address, setAddress] = useState();
    const [total, setTotal] = useState();
    const params = useParams();

    const navBack = () => {
        navigate(-1)
    };

    const getSubStr = (str) => {
        if(str&&str.length > 25){
            let subStr1 = str.substr(0, 7);
            let subStr2 = str.substr(str.length - 19, 19);
            return subStr1 + "..." + subStr2;
        }
       return str;
    };

    const displayPoster = (url) => {
        const parentDiv = document.getElementById('stageDiv');
        parentDiv.innerHTML ='';
        const result = document.createElement("img");
        result.style.minHeight = '100vh';
        result.style.width = '100%';
        result.style.position = 'absolute';
        result.style.left = '0';
        result.src = url;
        stageRef.current.appendChild(result);
        stageRef.current.scrollTo(0,0);
        result.onload = () => {
            setLoaded(true);
        };
    }

    const setInformation=(res)=>{
        setTokenId(res.data.tokenId);
        setOwner(res.data.ownedAt);
        setName(res.data.name);
        setPublisher(res.data.collectionInfo.publisher);
        setCreator(res.data.creator)
        setAddress(res.data.collectionInfo.contractAddress);
        setTotal(res.data.collectionInfo.amount)
    }

    const errorToast =() => {
        bottomToast('网络波动，请重试', 'center',()=>{
            navigate(`/detail/${params.id}`)
        })
    }

    useEffect(() => {
            if (state.isLogin) {
                fetch(serverAddress + "/nft/"+ params.id, { method: "GET" })
                    .then((res) => {
                        if (res.status === 200) {
                            res.json().then((res) => {
                                if (res.returnCode === "20000") {
                                    let url = res.data.thumbnailURL;
                                    let nftImg = document.createElement("img");
                                    nftImg.crossOrigin = "anonymous";
                                    nftImg.src = url;
                                    nftImg.onload = function(){
                                        setNft(nftImg.src);
                                        setNftLoaded(true);
                                    }
                                    setInformation(res)
                                }
                            }).catch(()=>errorToast());
                        }
                    });
            }
        }, [state.isLogin]);

    useEffect(() => {
        if (nftLoaded&&imgLoaded) {
            setTimeout(() => {
                fetch(serverAddress + "/poster/isUploaded?nftId="+ params.id, {
                    method:"GET",
                    headers: {"accessToken": state.accessToken}}).then((res) => {
                    if(res.status === 200){
                        res.json().then( r => {
                            if(r.returnCode === "20000"){
                                if(r.data.length === 0){
                                    htmlToImage.toBlob(stageRef.current).then(() => {
                                        htmlToImage.toBlob(stageRef.current).then(() => {
                                            htmlToImage.toBlob(stageRef.current).then(() => {
                                                htmlToImage.toBlob(stageRef.current).then((blob) => {
                                                    let img = new File([blob],'poster.png');
                                                    let formData = new FormData();
                                                    formData.append('file', img);
                                                    fetch(serverAddress + "/poster/uploadOss?nftId=" + params.id,{
                                                        method:"POST",
                                                        headers: {"accessToken": state.accessToken},
                                                        body: formData
                                                    }).then((res) => {
                                                        if(res.status === 200){
                                                            res.json().then(r => {
                                                                if(r.returnCode === "20000"){
                                                                    displayPoster(r.data);
                                                                }
                                                            })
                                                        }
                                                    }).catch(()=>errorToast())
                                                })
                                            })
                                        })
                                    })
                                }else{
                                    displayPoster(r.data);
                                }
                            }
                        })
                    }
                }).catch(()=> errorToast())
            },500);
        }
    }, [nftLoaded, imgLoaded]);

    useEffect(() => {
        if(!loaded){
                document.body.style.overflow='hidden';
        }else{
                 document.body.style.overflow='';
        }
    },[loaded])

    useEffect(() => {
        if(!state.isLogin){
            navigate('/')
        }
    },[])


    return(
        <div className="poster-page">
            <MideaLoading isShowing={!loaded} text={'海报生成中.../'}/>
            <div className="back-icon" onClick={navBack}></div>
            <div style={{padding: '25px 17px 0 0',position: 'absolute',right:'0', top: '0',zIndex:6}} onClick={()=>bottomToast('长按图片下载海报')}>
                <img src={Download} alt=''/>
            </div>
            <div ref={stageRef} id='stageDiv' className='stageDiv'>
                <div className='background'></div>
                <div className='poster-page-content'>
                    <div className="nft-swiper-title">{name}</div>
                    <div className='nft-title-background'></div>
                    <div className="nft-id">#{tokenId}/{total}</div>
                    <div className='poster-nft-image-container'>
                        <img className="poster-nft-image" src={nft} alt="" onLoad={()=>setImgLoaded(true)}/>
                    </div>
                    <div className="poster-page-info-container">
                        <div className="info-container-title">数字藏品信息</div>
                        <div className="info-content-container">
                            <div className="info-title">发行方</div>
                            <div className="info-content">{getSubStr(publisher)}</div>
                        </div>
                        <div className="info-content-container">
                            <div className="info-title">创作时间</div>
                            <div className="info-content">{owner?owner.toString().substr(0,10):''}</div>
                        </div>
                        <div className="info-content-container">
                            <div className="info-title">创作作者</div>
                            <div className="info-content">{getSubStr(creator)}</div>
                        </div>
                        <div className="info-content-container">
                            <div className="info-title">区块链唯一编号</div>
                            <div className="info-content">{getSubStr(address)}</div>
                        </div>
                    </div>
                    <div className="bottom-container">
                        <div className="left">
                            <img className='qrcode-image' src={`https://api.qrserver.com/v1/create-qr-code/?data=https%3A%2F%2Fmidea.cocafe.co%2Fhelp%2F${params.id}&amp;size=61x61`} alt="" title="" />
                            <div className="qrcode-text">
                                <div>扫描二维码</div>
                                <div>了解更多信息</div>
                            </div>
                        </div>
                        <div className="right"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
