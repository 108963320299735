import './frame.scss';
import { Link } from "react-router-dom";

export default function Frame(props){
    const {backgroundImage, contentImage, height, width, onClick, contentHeight, contentWidth, nftName, nftTokenID, lightBackground} = props;

    return(
        <div className={lightBackground? 'nft-card-wrapper' : null} onClick={onClick} style={{ zIndex: '3'}}>
            { nftTokenID ?
                <Link to={'/detail/' + nftTokenID}>
                        <div className='card-container' 
                             style={{backgroundImage: `url(${backgroundImage})`, height: height, width: width}}>
                            <img src={contentImage} alt=''
                                 className={'content-container'} style={{height: contentHeight, width: contentWidth}}/>
                        </div>
                </Link> :
                <div className='card-container' 
                     style={{backgroundImage: `url(${backgroundImage})`, height: height, width: width}}>
                    <img src={contentImage} alt=''
                         className={'content-container'} style={{height: contentHeight, width: contentWidth}}/>
                </div>
            }
            <div className={nftName? 'nft-name': null}>
                {nftName}
            </div>
        </div>
    )
}