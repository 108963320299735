import Background from "../../assets/midea/background/short-background.png";
import './MediaCongrats.scss';
import MenuBar from "../../components/menuIcon/menuIcon";
import NftBorder from '../../assets/midea/nft-border.png';
import PhotoDesc from "../../components/photoDesc/PhotoDesc";
import Button from "../../components/button/button";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import midea1 from "../../assets/midea/midea-nft-images/midea1.jpg";
import midea2 from "../../assets/midea/midea-nft-images/midea2.jpg";
import midea3 from "../../assets/midea/midea-nft-images/midea3.jpg";
import midea4 from "../../assets/midea/midea-nft-images/midea4.jpg";
import midea5 from "../../assets/midea/midea-nft-images/midea5.jpg";
import {useLocation} from "react-router-dom";

export default function MediaCongratsPage() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const { typeName } = state;
    const [nftIndex, setNftIndex] = useState(0);

    useEffect(()=> {
        if(typeName){
            nftInfo.map((item,index)=>{
                if(typeName.includes(item.title)){
                    setNftIndex(index);
                }
            })
        }

    },[typeName])

    const nftInfo = [
        {
            id: '01/05',
            title: '遥遥领鲜者',
            image: midea1,
            desc: `在家也有新奇遇
做个遥遥领「鲜」者`
        },{
            id: '02/05',
            title: '舒适星期日',
            image: midea2,
            desc: `舒适悠长星期日
偷瞄一下屋内好风光`
        },{
            id: '03/05',
            title: '双子领航者',
            image: midea3,
            desc: `全季候时刻焕新
畅享清新好时光`
        },{
            id: '04/05',
            title: '凉风尊享1号',
            image: midea4,
            desc: `在家里尊享自在
推开窗去漫游星际`
        },{
            id: '05/05',
            title: '这夏的确凉',
            image: midea5,
            desc: `让微微凉意吹散热浪
啊~这夏的确凉~`
        }
    ]

    const ToList = () =>{
        navigate('/list')
    }

    const ToActivity = () =>{
        navigate('/activity')
    }

    return (
        <div className='mideaCongratsContainer'>
            <MenuBar/>
            <img src = {Background} className='backgroundImage'/>
            <div className='content'>
                <div className="nft-swiper-title">「 {nftInfo[nftIndex]?.title} 」</div>
                <div className='nft-title-background'></div>
                <div className="nft-id">#{nftInfo[nftIndex]?.id}</div>
                <div className='nftContainer'>
                    <img src={NftBorder} className='nftBorder'/>
                    <img src={nftInfo[nftIndex]?.image} className='nftImg'/>
                </div>
                <PhotoDesc desc={nftInfo[nftIndex]?.desc} type={'2'}/>
                <div className='buttonGroupContainer'>
                    <Button text={'查看藏品'} clickEvent={ToList}/>
                    <Button text={'领取618优惠券'} clickEvent={ToActivity}/>
                </div>
            </div>
            <div className='dutyText'>
                本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。
            </div>
        </div>
    )
}