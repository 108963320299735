import "./detail.scss";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AuthContext, serverAddress, serverAddress2 } from "../../App";
import { responseCode } from "../../config";
import { useNavigate } from "react-router-dom";
import MenuIcon from "../../components/menuIcon/menuIcon";
import PhotoDesc from '../../components/photoDesc/PhotoDesc'
import Button from '../../components/button/button'


export default function Detail() {
    const navigate = useNavigate();
    let { id } = useParams();
    const [nftInfo, setInfo] = useState({});
    const [photo, setPhoto] = useState(null);
    const [article, setArticle] = useState('');
    const [assistNum, setAssistNum] = useState(0);
    const {state} = useContext(AuthContext);

    const goToPoster = () => {
        navigate("/poster/" + id);
    }

    const toActivity = () =>{
        navigate('/activity')
    }

    const getSubStr = (str) => {
        if(str&&str.length > 25){
            let subStr1 = str.substr(0, 7);
            let subStr2 = str.substr(str.length - 19, 19);
            return subStr1 + "..." + subStr2;
        }
       return str;
    };

    const bottomText = '本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。'

    useEffect(() => {
        try {
            fetch(serverAddress + "/nft/" + id, {
                method: "GET",
            }).then((r) => {
                r.json().then((result) => {
                    if (result.returnCode === responseCode.success) {
                        const { ownedAt, name, ownerUserInfo, displayURL, collectionInfo,creator, tokenId } = result.data;
                        setInfo({ ownedAt, name, ownerUserInfo, image:displayURL, publisher: collectionInfo.publisher, address: collectionInfo.contractAddress, creator: creator, tokenId, total:collectionInfo.amount });
                    }
                });
            });
        } catch (error) {
            console.log("error", error);
        }
    }, [id]);

    useEffect(() => {
        if(id){
            try {
                fetch(
                    serverAddress2 + `/midea/${id}/story`,
                    {
                        method: "GET",
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            setPhoto(result.data.photo)
                            setArticle(result.data.article)
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    },[id])

    useEffect(() => {
        if(state.userId && state.userId.length > 0){
            try {
                fetch(
                    serverAddress2 + `/${state.userId}/assistDetail`,
                    {
                        method: "GET",
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            setAssistNum(result.data.assistNum)
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    },[state.userId])

    return (
        <div className="detail-page">
            <MenuIcon/>
            {photo&&article?
            <div className='background'></div>:
            <div className='background2'></div>
            }
            <div className='detail-page-content'>
                <div className="nft-swiper-title">「 {nftInfo.name} 」</div>
                <div className='nft-title-background'></div>
                <div className="nft-id">#{nftInfo.tokenId}/{nftInfo.total}</div>
                <div className='nft-image-container'>
                    <img className="nft-image" src={nftInfo.image} alt=""/>
                </div>
                <div className='assist-container'>
                    <div className='eye-icon'></div>
                    <div className='assist-text'>已助力:</div>
                    <div className='assist-num'>{assistNum}次</div>
                </div>
                <div className="detail-page-info-container">
                    <div className="info-container-title">数字藏品信息</div>
                    <div className="info-content-container">
                        <div className="info-title">发行方</div>
                        <div className="info-content">{getSubStr(nftInfo.publisher)}</div>
                    </div>
                    <div className="info-content-container">
                        <div className="info-title">创作时间</div>
                        <div className="info-content">{nftInfo.ownedAt?nftInfo.ownedAt.toString().substr(0,10):''}</div>
                    </div>
                    <div className="info-content-container">
                        <div className="info-title">创作作者</div>
                        <div className="info-content">{getSubStr(nftInfo.creator)}</div>
                    </div>
                    <div className="info-content-container">
                        <div className="info-title">区块链唯一编号</div>
                        <div className="info-content">{getSubStr(nftInfo.address)}</div>
                    </div>
                </div>
                {
                    photo&&article?
                    <div className="photo-container">
                        <img className="photo-image" src={photo} alt=""/>
                    </div>:null
                }
                { photo&&article?<PhotoDesc desc={article}/>:null}
                <div className="button-groups">
                    <Button text={'生成海报'} clickEvent={goToPoster}/>
                    <Button text={'领取618优惠券'} clickEvent={toActivity}/>
                </div>
            </div>
            <div className="bottom-text">{bottomText}</div>
        </div>
    );
}
