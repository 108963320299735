import "./help.scss";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AuthContext, serverAddress, serverAddress2 } from "../../App";
import { responseCode } from "../../config";
import { useNavigate } from "react-router-dom";
import MenuIcon from "../../components/menuIcon/menuIcon";
import PhotoDesc from '../../components/photoDesc/PhotoDesc'
import Button from '../../components/button/button'
import { bottomToast } from "../../utils/toast";

export default function Help() {
    const navigate = useNavigate();
    let { id } = useParams();
    const [nftInfo, setInfo] = useState({});
    const [photo, setPhoto] = useState(null);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [article, setArticle] = useState('');
    const [assistNum, setAssistNum] = useState(0);
    const {state} = useContext(AuthContext);
    // 被助力人的userid
    const [assistUserId, setAssistUserId] = useState(null);
    const [assistStatus, setAssistStatus] = useState(null);

    const getSubStr = (str) => {
        if(str&&str.length > 25){
            let subStr1 = str.substr(0, 7);
            let subStr2 = str.substr(str.length - 19, 19);
            return subStr1 + "..." + subStr2;
        }
       return str;
    };

    const assistFriend = () => {
      if (buttonDisable === true) {
        return false
      }
      setButtonDisable(true)
      if(state.isLogin){
        if (assistUserId===state.userId) {
          bottomToast('您不能助力自己')
          setButtonDisable(false)
          return false
        }
        if (assistStatus === 'current user already assisted request user') {
          bottomToast('您已助力过该好友')
          setButtonDisable(false)
          return false
        }
        if(assistUserId && assistUserId.length > 0){
          try {
              fetch(
                  serverAddress2 + `/${assistUserId}/assistance`,
                  {
                      method: "POST",
                      headers: {"accessToken": state.accessToken},
                  }
              ).then((r) => {
                  r.json().then((result) => {
                      if(result.returnCode === responseCode.success ){
                        setAssistNum(result.data.assistNum);
                        setAssistStatus('current user already assisted request user')
                        bottomToast('助力成功')
                      } else if (result.msg === 'Request too much') {
                        bottomToast('请稍后尝试')
                      }
                      setButtonDisable(false)
                  })
              } );
          } catch (error) {
              setButtonDisable(false)
              console.log("error", error);
          }
        }
      }else{
        setButtonDisable(false)
        navigate('/login/help');
      }
    }

    useEffect(() => {
      if(state.accessToken && state.accessToken.length > 0&&assistUserId&& assistUserId.length > 0){
          try {
              fetch(
                  serverAddress2 + `/${assistUserId}/assistance`,
                  {
                      method: "GET",
                      headers: {"accessToken": state.accessToken},
                  }
              ).then((r) => {
                  r.json().then((result) => {
                      if(result.returnCode === responseCode.success ){
                        setAssistStatus(result.data.checkMsg)
                      }
                  })
              } );
          } catch (error) {
              console.log("error", error);
          }
      }
    },[state.accessToken, assistUserId])

    const bottomText = '本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。'

    useEffect(() => {
        try {
            fetch(serverAddress + "/nft/" + id, {
                method: "GET",
            }).then((r) => {
                r.json().then((result) => {
                    if (result.returnCode === responseCode.success) {
                        const { ownedAt, name, ownerUserInfo, displayURL, collectionInfo,creator, tokenId } = result.data;
                        setInfo({ ownedAt, name, ownerUserInfo, image:displayURL, publisher: collectionInfo.publisher, address: collectionInfo.contractAddress, creator: creator, tokenId, total:collectionInfo.amount });
                        setAssistUserId(ownerUserInfo.userId)
                    }
                });
            });
        } catch (error) {
            console.log("error", error);
        }
    }, [id]);

    useEffect(() => {
        if(assistUserId && assistUserId.length > 0){
            try {
              fetch(
                  serverAddress2 + `/${assistUserId}/assistDetail`,
                  {
                      method: "GET",
                  }
              ).then((r) => {
                  r.json().then((result) => {
                      console.log(result, 'result');
                      if(result.returnCode === responseCode.success ){
                          setAssistNum(result.data.assistNum)
                      }
                  })
              } );
          } catch (error) {
              console.log("error", error);
          }
        }
    },[assistUserId])

    useEffect(() => {
        if(id){
            try {
                fetch(
                    serverAddress2 + `/midea/${id}/story`,
                    {
                        method: "GET",
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            setPhoto(result.data.photo)
                            setArticle(result.data.article)
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    },[id])

    const joinActivity = () => {
        navigate('/')
    }

    return (
        <div className="help-page">
            <MenuIcon/>
            {photo&&article?
            <div className='background'></div>:
            <div className='background2'></div>
            }
            <div className='help-page-content'>
                <div className="nft-swiper-title">「 {nftInfo.name} 」</div>
                <div className='nft-title-background'></div>
                <div className="nft-id">#{nftInfo.tokenId}/{nftInfo.total}</div>
                <div className='nft-image-container'>
                    <img className="nft-image" src={nftInfo.image} alt=""/>
                </div>
                <div className='assist-container'>
                    <div className='eye-icon'></div>
                    <div className='assist-text'>已助力:</div>
                    <div className='assist-num'>{assistNum}次</div>
                </div>
                <div className="button-groups">
                  <Button text={'助力好友'} clickEvent={assistFriend}/>
                  <Button text={'我也要参与活动'} clickEvent={joinActivity}/>
                </div>
                <div className="help-page-info-container">
                    <div className="info-container-title">数字藏品信息</div>
                    <div className="info-content-container">
                        <div className="info-title">发行方</div>
                        <div className="info-content">{getSubStr(nftInfo.publisher)}</div>
                    </div>
                    <div className="info-content-container">
                        <div className="info-title">创作时间</div>
                        <div className="info-content">{nftInfo.ownedAt?nftInfo.ownedAt.toString().substr(0,10):''}</div>
                    </div>
                    <div className="info-content-container">
                        <div className="info-title">创作作者</div>
                        <div className="info-content">{getSubStr(nftInfo.creator)}</div>
                    </div>
                    <div className="info-content-container">
                        <div className="info-title">区块链唯一编号</div>
                        <div className="info-content">{getSubStr(nftInfo.address)}</div>
                    </div>
                </div>
                {
                    photo&&article?
                    <div className="photo-container">
                        <img className="photo-image" src={photo} alt=""/>
                    </div>:null
                }
                { photo&&article?<PhotoDesc desc={article}/>:null}
            </div>
            <div className="bottom-text">{bottomText}</div>
        </div>
    );
}
