import './mideaClaimPage.scss';
import MenuBar from "../../components/menuIcon/menuIcon";
import UploadProcess from "../../components/uploadProcess/UploadProcess";
import {useContext, useEffect, useState} from "react";
import {AuthContext, serverAddress2} from "../../App";
import Background from '../../assets/midea/background/short-background.png';
import NftSwiper from "../../components/nftSwiper/nftSwiper";
import Button from "../../components/button/button";
import Loading from '../../assets/midea/loading.gif';
import {useNavigate} from "react-router";
import midea1 from "../../assets/midea/midea-nft-images/midea1.jpg";
import midea2 from "../../assets/midea/midea-nft-images/midea2.jpg";
import midea3 from "../../assets/midea/midea-nft-images/midea3.jpg";
import midea4 from "../../assets/midea/midea-nft-images/midea4.jpg";
import midea5 from "../../assets/midea/midea-nft-images/midea5.jpg";
import {bottomToast} from "../../utils/toast";

export default  function MediaClaimPage(){
    const {state} = useContext(AuthContext);
    const [currentIndex,setIndex] = useState(0);
    const [count, setCount] = useState(undefined);
    const [loading ,setLoading] = useState(false);
    const navigate = useNavigate();

    const nftInfo = [
        {
            id: '01/05',
            title: '遥遥领鲜者',
            image: midea1
        },{
            id: '02/05',
            title: '舒适星期日',
            image: midea2
        },{
            id: '03/05',
            title: '双子领航者',
            image: midea3
        },{
            id: '04/05',
            title: '凉风尊享1号',
            image: midea4
        },{
            id: '05/05',
            title: '这夏的确凉',
            image: midea5
        }
    ]

    const handleSlideChange = (e) => {
        let index = e.realIndex;
        setIndex(index);
    }

    const mint = () => {
        if(state.accessToken && state.accessToken.length > 0){
            setLoading(true);
            try {
                fetch(
                    serverAddress2 + "/batchMint",
                    {
                        method: "POST",
                        headers: {"accessToken": state.accessToken},
                        body: JSON.stringify({typeName: `「${nftInfo[currentIndex].title}」`})
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === '20000'){
                            navigate('/congrats', { state: { typeName: result.data.typeName } })
                        }else{
                            bottomToast(result.msg);
                        }
                    }).finally(()=>{
                        setLoading(false);
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    }

    useEffect(()=>{
        if(document.getElementsByClassName('swiper-slide-next')[0].style){
            document.getElementsByClassName('swiper-slide-next')[0].style.filter = 'brightness(0.5)';
        }

        if(document.getElementsByClassName('swiper-slide-prev')[0].style){
            document.getElementsByClassName('swiper-slide-prev')[0].style.filter = 'brightness(0.5)';
        }

        if(document.getElementsByClassName('swiper-slide-active')[0].style){
            document.getElementsByClassName('swiper-slide-active')[0].style.filter = 'brightness(1)';
        }

    },[currentIndex])

    useEffect(()=>{
        if(state.accessToken && state.accessToken.length > 0){
            try {
                fetch(
                    serverAddress2 + "/mintNum",
                    {
                        method: "GET",
                        headers: {"accessToken": state.accessToken},
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === '20000'){
                            setCount(result.data);
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    },[state.accessToken]);

    return(
        <div className='mediaClaimPageContainer'>
            {loading?
                <div className='loadingContent'>
                    <img src={Background} className='loadingBackground'/>
                    <img src = {Loading} className='loadingImage'/>
                    <div className='loadingText'>努力铸造中.../</div>
                    <div className='loadingDutyText'>
                        本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。
                    </div>
                </div>
                :
                <>
                    <div className='background'/>
                    <MenuBar/>
                    <div className='content'>
                        <div className='title'>领取数字藏品</div>
                        <UploadProcess index={1}/>
                        <NftSwiper nftInfo={nftInfo} handleSlideChange={handleSlideChange} disabledPlay={true}/>
                        <div className="nft-swiper-title">「 {nftInfo[currentIndex].title} 」</div>
                        <div className='nft-title-background'></div>
                        <div className="nft-id">#{nftInfo[currentIndex].id}</div>
                        <div className='leftCount'>剩余数量:{count?.[`type${currentIndex+1}`]}</div>
                        <Button text={'确认铸造'} clickEvent={mint}/>
                        <div className='dutyText'>
                            本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。
                        </div>
                    </div>
                </>
            }
        </div>
    )
}