import BackgroundImage from "./assets/background.png";
import "./background.scss";
import ornament1 from "./assets/ornament.svg";
import ornament2 from "./assets/ornament2.png";
import ornament3 from "./assets/ornament3.png";
import ornament4 from "./assets/ornament4.svg";
import text from "./assets/text.png";
import MideaBackground from '../../assets/midea/background/short-background.png';

export default function Background(props) {
  const { ornamentConfig, textConfig, style } = props;

  return (
    <div
      className="backgroundContainer"
      style={{ backgroundImage: `url(${MideaBackground})` }}
    >
      { textConfig && <img src={text} className="text" alt="" style={style.background} />}
      { ornamentConfig[0] && <img src={ornament1} className="ornament1" alt="" />}
      { ornamentConfig[1] && <img src={ornament2} className="ornament2" alt="" />}
      { ornamentConfig[2] && <img src={ornament3} className="ornament3" alt="" />}
      { ornamentConfig[3] && <img src={ornament4} className="ornament4" alt="" />}
    </div>
  );
}
