import React from 'react';
import { Dialog } from '@mui/material';
import './cropperDialog.css';
import  BackICon from '../../../components/backButton/assets/back.png';

function CropperDialog(props) {
    const { uploadImage, setOpen, open, loading } = props;

    const handleClose = () => {
        setOpen(false);
        document.getElementById("uploadImg").value = '';
    }

    return (
        <Dialog fullScreen open={open} onClose={handleClose} PaperProps={{style: {backgroundColor: "black"}}}>
            <div className="cropper">
                <div className="cropper-title">
                   <img className="back" src = {BackICon} width="20" height="16" onClick={handleClose} alt=''/>
                    调整尺寸
                </div>
                <div style={{width: '305px', height: '305px', margin: 'auto'}}>
                    <img alt="" id="cropImg" />
                </div>
                <div>
                    <button className='confirm' onClick={uploadImage} style={{ background: loading?'grey':'#2A7DF9'}}>{loading? '上传中':'确认修改'}</button>
                </div>
            </div>
        </Dialog>
    )
}

export default CropperDialog;