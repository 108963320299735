import './nftSwiper.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { FreeMode, Autoplay } from "swiper";

SwiperCore.use([FreeMode, Autoplay]);

export default function NftSwiper(props) {
  const {nftInfo, handleSlideChange, disabledPlay, style, loopDisable = false} = props;

  return (
    <div className="nft-swiper" style={{ height: style? '200px':'350px' }}>
      <div style={{ height: style? '200px':'350px', width: "100%", position: 'absolute', marginTop: style? '-40px':'10px'}}>
        <Swiper slidesPerView={'auto'}
          spaceBetween={16} centeredSlides={true}
                autoplay={
                    disabledPlay ? false :
                        nftInfo.length > 1 ? {delay: 1000, disableOnInteraction: false} : false}
                loop={loopDisable?false:true}
          className="home-swiper"
          onSlideChange={handleSlideChange}>
          {nftInfo.map((nft,index)=>{
            return (
                <div key={index}>
                    <SwiperSlide className="nft-card" key={index} style={{height: style?.frame.height, width: style?.frame.width}}>
                        <img className="nft-image" src={nft.image} alt="" style={{height: style?.card.height, width: style?.card.width}}/>
                        {style?<div className='nft-subTitle' >{nft.title}</div>: null}
                    </SwiperSlide>
                </div>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}