import "./about.scss";
import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";

export default function About(){
    const navigate = useNavigate();

    const navBack = () => {
        navigate(-1)
    };
    const content = {
        title: '关于',
        content: [
            'NFT创意作品具有作品的唯一性、不可分割性以及不可被篡改的特点，此商品具有特殊性，因此不支持退换，谢谢您的理解与配合！',
            '上述NFT创意作品的知识产权由美的公司拥有，NFT的所有权人或者合法拥有者不得将上述NFT创意作品用于任何商业途径以及任何非法途径。',
            '本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。'
        ]
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return(
        <div id="about-container">
            <div className='background'></div>
            <div className="back-icon" onClick={navBack}></div>
            <div className="about-page-content">
                <div id='about-content'>
                    <div id="about-title" >
                        {content.title}
                    </div>
                    <div>
                        {
                            content.content.map((content,index)=>{
                                return <div  key={index} className='lineBetween'>{content}</div>
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}