import './App.scss';
import { Routes, Route, BrowserRouter} from "react-router-dom";
import React from "react";
import Poster from "./pages/posterPage/Poster";
import Claim from "./pages/claimPage/ClaimConfigsPage";
import NftList from "./pages/nftListPage/NftListConfigPage";
import {createContext, useEffect, useReducer} from "react";
import {responseCode} from "./config";
import MenuBar from "./components/MenuBar";
import About from "./pages/aboutPage/About";
import Congrats from "./pages/congratsPage/CongratsConfigsPage";
import Login from "./pages/loginPage/Login";
import Home from "./pages/homePage/Home";
import Detail from './pages/detailPage/Detail';
import Help from "./pages/helpPage/Help";
import Upload from "./pages/UploadPage/UploadPage";
import UploadSuccessPage from "./pages/UploadPage/UploadSucessPage";
import MediaClaimPage from "./pages/mideaClaimPage/mideaClaimPage";
import MediaCongratsPage from "./pages/mediaCongrats/MediaCongrats";
import ActivityPage from "./pages/activityPage/ActivityPage";

const initialState = {
    isLogin: null,
    userId: null,
    accessToken: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem("userId", action.payload.userId);
            localStorage.setItem("accessToken", action.payload.accessToken);
            return {
                ...state,
                isLogin: true,
                userId: action.payload.userId,
                accessToken: action.payload.accessToken,
            };
        case "LOGOUT":
            localStorage.removeItem("userId");
            localStorage.removeItem("accessToken");
            return {
                ...state,
                isLogin: false,
                userId: null,
                accessToken: null,
            };
    }
}

export const portalAddress = process.env.REACT_APP_PORTAL_ADDRESS;
export const serverAddress = process.env.REACT_APP_WALLET_ADDRESS;
export const serverAddress2 = process.env.REACT_APP_SERVER_ADDRESS;
export const appId = "wilding";
export const AuthContext = createContext();

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(async() => {
        let userId = localStorage.getItem("userId");
        let accessToken = localStorage.getItem("accessToken")
        if (userId && accessToken) {
            try {
                const response = await fetch( portalAddress + "/user/token", {
                    method: "GET",
                    headers: { accessToken },
                } );
                const json = await response.json();
                if (json.returnCode === responseCode.success) {
                    dispatch({
                        type: "LOGIN",
                        payload: {
                            isLogin: true,
                            userId,
                            accessToken
                        }
                    })
                }else{
                    dispatch({
                        type: "LOGOUT",
                    })
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            dispatch({
                type: "LOGOUT",
            })
        }
    },[])
  return (
    <div className="App" id='app'>
      <AuthContext.Provider value = {{state, dispatch}}>
          <BrowserRouter>
              <Routes>
                      <Route path = "/" element = {<Home />}/>
                      <Route path = "/about" element = {<About />}/>
                      <Route path = "/claim" element = {<Claim />} />
                      <Route path = "/list" element = {<NftList />} />
                      <Route path = "/congrats" element = {<MediaCongratsPage />} />
                      <Route path = "/detail/:id" element = {<Detail />} />
                      <Route path = "/help/:id" element = {<Help />} />
                      <Route path = "/poster/:id" element = {<Poster />} />
                      <Route path = "/login/:from" element = {<Login />} />
                      <Route path = "/upload" element = {<Upload />} />
                      <Route path = '/uploaded' element = {<UploadSuccessPage/>}/>
                      <Route path = '/mediaClaim' element = {<MediaClaimPage/>}/>
                      <Route path = '/activity' element = {<ActivityPage/>}/>
              </Routes>
          </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
