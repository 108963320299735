import './mideaLoading.scss'

export default function MideaLoading ({text, isShowing}) {

  const bottomText = '本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。'

  return (
    <div className="midea-loading" style={{display: isShowing? 'flex' : 'none'}}>
      <div className='loading-background'></div>
      <div className='loading-content'>
        <div className='loading-icon'></div>
        <div className="loading-text">{text}</div>
        <div className="bottom-text">{bottomText}</div>
      </div>
    </div>
  )
}