import back from "./assets/back.png";
import {useNavigate} from "react-router";
import { useLocation } from "react-router";
import blackBack from './assets/blackBack.svg';
import whiteBack from '../../assets/midea/whiteBack.png';

export default function BackButton(props){
    const { clickEvent } = props;

    const navigate = useNavigate();
    const currentLocation = useLocation();

    const goBack = () => {
        if(clickEvent){
            navigate('/');
        }else{
            navigate(-1);
        }
    }

    const getBackButton = ()=>{
        switch (currentLocation.pathname){
            case '/login':
                return blackBack;
            default: return whiteBack ;
        }
    }

    return (
        <div onClick={goBack} style={{
            width: '8px',
            height: '13px',
            position: 'absolute',
            top: '26px',
            left: '26px',
            zIndex: '2'
        }}>
            {currentLocation.pathname === '/claim' ? null :
                <img src={getBackButton()} style={{width: '100%', height: '100%'}} alt=''/>}
        </div>
    )
}