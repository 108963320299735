import Background from '../../assets/midea/background/short-background.png';
import './UploadSuccessPage.scss';
import BackButton from "../../components/backButton/BackButton";
import PhotoFrame from '../../assets/midea/photoFrame.png';
import PhotoDesc from "../../components/photoDesc/PhotoDesc";
import Button from "../../components/button/button";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";
import {useContext, useEffect, useState} from "react";
import {AuthContext, serverAddress2} from "../../App";

export default  function UploadSuccessPage(){
    const navigate = useNavigate();
    const {state} = useContext(AuthContext);
    const { story, imgUrl } = useLocation().state;
    const [img, setImg] = useState(imgUrl);
    const [desc, setDesc] = useState(story);

    console.log( useLocation().state)

    const toClaimPage =() =>{
        navigate('/mediaClaim')
    }

    useEffect(()=>{
        const userId = localStorage.getItem("userId");
            if(state.accessToken && state.accessToken.length > 0){
                try {
                    fetch(
                        serverAddress2 + `/${userId}/story`,
                        {
                            method: "GET",
                            headers: {"accessToken": state.accessToken},
                        }
                    ).then((r) => {
                        r.json().then((result) => {
                          if(result.returnCode === '20000'){
                              setImg(result.data.photo);
                              setDesc(result.data.article);
                          }
                        })
                    } );
                } catch (error) {
                    console.log("error", error);
                }
        }
    },[state.accessToken])

    console.log(img)

    return(
        <div className='uploadedContainer'>
            <img src = {Background} className='backgroundImage'/>
            <div className='content'>
                <BackButton/>
                <div className='title'>上传成功</div>
                <div className='photoContainer'>
                    <img src = {img} className='photo'/>
                    <img src = {PhotoFrame} className='photoFrame'/>
                </div>

                <PhotoDesc desc={desc}/>

                <div style={{ marginBottom: '45px'}}/>

                <Button text={'领取数字藏品'} clickEvent={toClaimPage}/>
            </div>

            <div className='dutyText'>
                本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。
            </div>

        </div>
    )
}