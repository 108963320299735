import './ActivityPage.scss';
import JdMall from '../../assets/midea/jd.png';
import TbMall from '../../assets/midea/tb.png';
import MallFrame from "../../assets/midea/mallFrame.png";
import BackButton from "../../components/backButton/BackButton";
import JD from '../../assets/midea/jdMidea.png';
import TB from '../../assets/midea/tmallMidea.png';
import JdMallLink from '../../assets/midea/jdMallLInkPic.png';
import TmallLink from '../../assets/midea/tmallLinkPic.png';
import MiniProgram from '../../assets/midea/miniProgram.png';
import MideaIcon from '../../assets/midea/mideaIcon.png';
import MideaQrcode from '../../assets/mideaQrcode.png';
import EvaluationImg from '../../assets/midea/evaluation.png';
import EvaluationDesc from '../../assets/midea/evaluationDesc.png';
import EvaluationLink from '../../assets/midea/evaluationLink.png';
import MediaForHomeTitle from '../../assets/midea/mideaForHomeTitle.png';
import MediaForHomeDesc from '../../assets/midea/MideaForHomeDesc.png';
import MediaForHomeLink from '../../assets/midea/mediaForHomeLink.png';

export default function ActivityPage(){

    const evaluationClick = () =>{
        document.getElementById('evaluation').click();
    }

    const wechatClick = () =>{
        document.getElementById('wechatCLick').click();
    }

    return(
        <div className='activityPageContainer'>
            <a href='weixin://dl/business/?t=5E99HXX1Q0j' id='evaluation' style={{display: 'none'}}/>
            <a href='weixin://dl/business/?t=h27KJ3eHGEo' id='wechatCLick' style={{display: 'none'}}/>
            <div className='background'/>
            <div className='content'>
                <BackButton clickEvent={true}/>
                <img src={EvaluationImg} className='mallIcon'/>
                <div className='mallFrameContainer'>
                    <img src={MallFrame} className='mallFrame'/>
                    <div className='mallContent'>
                        <img src={EvaluationDesc} className='evaluationDesc'/>
                        <img src={EvaluationLink} className='evaluationLink' onClick={evaluationClick}/>
                    </div>
                </div>

                <img src={MediaForHomeTitle} className='mallIcon'/>
                <div className='mallFrameContainer'>
                    <img src={MallFrame} className='mallFrame2'/>
                    <div className='mallContent'>
                        <img src={MediaForHomeDesc} className='mediaForHomeDesc'/>
                        <img src={MediaForHomeLink} className='mediaForHomeLink' onClick={wechatClick}/>
                    </div>
                </div>


                {/*<img src={TbMall} className='mallIcon'/>*/}
                {/*<div className='mallFrameContainer'>*/}
                {/*    <img src={MallFrame} className='mallFrame'/>*/}
                {/*    <div className='mallContent'>*/}
                {/*        <img src={TB} className='tbMallMideaIcon'/>*/}
                {/*        <img src={TmallLink} className='picLink' onClick={TbClick}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className='dutyText'>
                本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。
            </div>
        </div>
    )
}