import './PhotoDesc.scss';
// import LeftIcon from './assets/leftIcon.png'
// import RightIcon from './assets/rightIcon.png';
import LeftIcon from '../../assets/midea/left-quota.png'
import RightIcon from '../../assets/midea/right-quota.png'

export default function PhotoDesc(props){
    const {desc, type} = props;

    return(
        <div className='PhotoDescContainer'>
            <div className='leftIconContainer'>
                <img src={LeftIcon} className='iconImage'/>
            </div>
            { 
                type === '2'?
                <div className='textContainer2'>{desc}</div> :
                <div className='textContainer'>{desc}</div>
            }
            <div className='RightIconContainer'>
                <img src={RightIcon} className='iconImage'/>
            </div>
        </div>
    )
}