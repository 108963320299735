import './UploadPage.scss';
import MenuBar from '../../components/menuIcon/menuIcon';
import UploadProcess from '../../components/uploadProcess/UploadProcess.js';
import Button from '../../components/button/button';
import UploadFrame from './assets/uploadFrame.png';
import CameraIcon from './assets/cameraIcon.png';
import TextFrame from './assets/textFrame.png';
import StoryDesc from './assets/story.png';
import Activity from './assets/activityDesc.png';
import Prize from './assets/prize.png';
import {useContext, useEffect, useState} from "react";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import CropperDialog from "./components/cropperDialog";
import {AuthContext, serverAddress2} from "../../App";
import {useNavigate} from "react-router";
import NftSwiper from "../../components/nftSwiper/nftSwiper";
import midea1 from "../../assets/midea/midea-nft-images/midea1.jpg";
import midea2 from "../../assets/midea/midea-nft-images/midea2.jpg";
import midea3 from "../../assets/midea/midea-nft-images/midea3.jpg";
import midea4 from "../../assets/midea/midea-nft-images/midea4.jpg";
import midea5 from "../../assets/midea/midea-nft-images/midea5.jpg";
import {bottomToast} from "../../utils/toast";
import BackButton from "../../components/backButton/BackButton";

export default  function  UploadPage(){
    const {state} = useContext(AuthContext);
    const [currentIndex,setIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const [cropper, setCropper] = useState();
    const [imgUrl, setImgUrl] = useState(undefined);
    const [story , setStory] = useState(undefined);
    const [cropImg, setCropImg] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const nftInfo = [
        {
            id: '01/05',
            title: '「 遥遥领鲜者 」',
            image: midea1
        },{
            id: '02/05',
            title: '「 舒适星期日 」',
            image: midea2
        },{
            id: '03/05',
            title: '「 双子领航者 」',
            image: midea3
        },{
            id: '04/05',
            title: '「 凉风尊享1号 」',
            image: midea4
        },{
            id: '05/05',
            title: '「 这夏的确凉 」',
            image: midea5
        }
    ]

    const handleSlideChange = (e) => {
        let index = e.realIndex;
        setIndex(index);
    }

    const ConfirmUpload = () => {
        if(state.accessToken && state.accessToken.length > 0){
            try {
                fetch(
                    serverAddress2 + "/uploadStory",
                    {
                        method: "POST",
                        headers: {"accessToken": state.accessToken},
                        body: JSON.stringify({photo: imgUrl,article: story})
                    }
                ).then((r) => {
                    r.json().then((result) => {
                     if(result.returnCode === '20000'){
                         navigate('/uploaded', { state: { story: story, imgUrl: cropImg } });
                     }else{
                         bottomToast(result.msg);
                     }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    }

    const skip = ()=> {
        if(state.accessToken && state.accessToken.length > 0){
            try {
                fetch(
                    serverAddress2 + "/skip",
                    {
                        method: "POST",
                        headers: {"accessToken": state.accessToken}
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === '20000'){
                            navigate('/mediaClaim');
                        }else{
                            bottomToast(result.msg);
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    }

    const getImage = () => {
        const file = document.getElementById("uploadImg").files[0];
        setTimeout(()=>{
            setOpen(true);
            document.getElementById("cropImg").src = window.URL.createObjectURL(file);
            const image = document.getElementById("cropImg");

            let CROPPER = new Cropper(image, {
                aspectRatio: 16 / 16,
                viewMode: 3,
                autoCropArea: 1,
                dragMode: "move",
            });

            setCropper(CROPPER);
        },500)
    };

    const chooseImage = () => {
        document.getElementById("uploadImg").click();
        if (cropper) {
            cropper.destroy();
        }
    };

    function uploadImage() {
        cropper.getCroppedCanvas({
            maxWidth: 4096,
            maxHeight: 4096,
            fillColor: "#fff",
            imageSmoothingEnabled: true,
            imageSmoothingQuality: "high",
        }).toBlob((blob) => {
            setLoading(true);
            let img = new File([blob],'avatar.png');
            let formData = new FormData();
            formData.append('file', img);
            document.getElementById("uploadImg").value = '';
            setCropImg(window.URL.createObjectURL(img));
            if(state.accessToken && state.accessToken.length > 0){
                try {
                    fetch(
                        serverAddress2 + "/uploadOss",
                        {
                            method: "POST",
                            headers: {"accessToken": state.accessToken},
                            body: formData
                        }
                    ).then((r) => {
                        r.json().then((result) => {
                          if(result.returnCode === '20000'){
                            setImgUrl(result.data)
                          }else{
                              bottomToast(result.msg);
                          }
                        }).finally(()=>{
                            setOpen(false);
                            setLoading(false);
                        })
                    } );
                } catch (error) {
                    console.log("error", error);
                }
            }
        });
    };

    return(
        <div className='upload-page-container' >
            <div className='background-image'>

            </div>
            <BackButton/>
            <input style={{ display: "none" }} type="file" accept="image/png, image/jpeg" id="uploadImg" onChange={() => {getImage()}}/>
            <div className='content'>
                <div className='header'>
                    <div style={{height: '24px',width: '24px',marginLeft: '15px'}}/>
                    <div className='title'>上传旧家电照片</div>
                    <div className='skipText' onClick={skip}>跳过</div>
                </div>
                <UploadProcess index={0}/>

                <div className='uploadContainer'>
                    <img src={UploadFrame} className='uploadFrame'/>
                    <img src={CameraIcon} className='cameraIcon'/>
                    {cropImg ? <img src={cropImg} className='uploadImg' onClick={() => chooseImage()}/> :
                        <div className='uploadBackground' onClick={() => chooseImage()}/>}
                    {
                        cropImg ? null :
                            <div className='uploadText'>
                                <div>上传一张</div>
                                <div>我的旧家电照片</div>
                            </div>
                    }
                </div>

                <div className='storyContainer'>
                    <img src = {StoryDesc} style={{ width: '189px',height: '50px'}}/>
                    <div className='textFrameContainer'>
                        <img src={TextFrame} className='textFrame'/>
                        <textarea className='textArea' maxLength="50" placeholder='请填写与旧家电的故事，不多于50字'
                        onChange={(e)=>{setStory(e.target.value)}}
                        onKeyDown={(e)=> {
                            if(e.code === 'Enter'){window.event.returnValue = false}
                        }}/>
                    </div>
                </div>

                {/*<div className='activityContainer'>*/}
                {/*    <img src = {Activity} style={{ width: '189px',height: '50px'}}/>*/}
                {/*    <div className='textFrameContainer'>*/}
                {/*        <img src={TextFrame} className='textFrame'/>*/}
                {/*        <div className='text'>美的空调在618发起#以旧换新从新开始#活动主题，让用户焕新生活，从焕新空气开始。用户可以上传家中旧家电的照片，并配以不少于50字与旧家电的故事，即可获赠NFT限量数字藏品一幅及额外的50元优惠券。数字藏品领取后可分享到各大社交平台，邀请好友助力，赢取限量100名原价回收旧家电的名额。</div>*/}
                {/*    </div>*/}
                {/*</div>*/}


                <div className='prizeContainer'>
                    <img src = {Prize} style={{ width: '79px',height: '50px', marginBottom:'25px'}}/>
                    <div className='slideContainer'>
                        <NftSwiper nftInfo={nftInfo} handleSlideChange={handleSlideChange} loopDisable={true}
                        style={{ frame: {height: '124px',width:'115px'}, card: {height: '103px',width: '103px'}}}/>
                    </div>
                </div>


                <Button text={'确认上传'} clickEvent={ConfirmUpload}/>
                <div className='dutyText'>
                    本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。
                </div>
                <CropperDialog uploadImage={uploadImage} setOpen={setOpen} open={open} loading={loading}/>
            </div>
        </div>
    )
}