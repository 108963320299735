import './button.scss';

export default function Button(props){
    const {text, clickEvent} = props;

    return(
        <div className='buttonContainer' onClick={clickEvent}>
            <div className='underBorder'>{text}</div>
        </div>
    )
}