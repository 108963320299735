import './UploadProcess.scss';
import HookIcon from '../../assets/hook.png';
import {useContext, useEffect, useState} from "react";
import {AuthContext, serverAddress2} from "../../App";
import nftImage1 from "../../assets/midea/nft-image1.png";
import {bottomToast} from "../../utils/toast";

export default function UploadProcess(props){
    const { index } = props;
    const {state} = useContext(AuthContext);
    const [status, setStatus] = useState();

    useEffect(()=>{
        if(state.accessToken && state.accessToken.length > 0){
            try {
                fetch(
                    serverAddress2 + "/eventStatus",
                    {
                        method: "GET",
                        headers: {"accessToken": state.accessToken}
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === '20000'){
                            setStatus(result.data);
                        }else{
                            bottomToast(result.msg);
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    },[state.accessToken])


    return(
        <div className='upload-process-container'>
            <div className='process-bar'>
                <div className='circle'
                     style={{ background:'#4FB7FF', color: '#FFFFFF'}}>
                    { status?.hasUploadStory || status?.hasSkip  ? <img  src={HookIcon} className='hook'/> : 1}
                </div>
                <div className='line'/>
                <div className='circle' style={{
                    background: (status?.hasUploadStory || status?.hasSkip) && !status?.hasRebate ? '#4FB7FF' : '#FFFFFF',
                    color: (status?.hasUploadStory || status?.hasSkip) && !status?.hasRebate ? '#FFFFFF':'#B7B7B7'}}>
                    { status?.hasCollect ? <img  src={HookIcon} className='hook'/> : 2}
                </div>
                { status?.hasCollect ? <div className='line'/> : <div className='dashedLine'/>}
                <div className='circle' style={{
                    background: (status?.hasUploadStory || status?.hasSkip) && status?.hasCollect ?'#4FB7FF' : '#FFFFFF',
                    color: (status?.hasUploadStory || status?.hasSkip) && status?.hasCollect ?'#FFFFFF':'#B7B7B7'}}>
                    { status?.hasRebate  ? <img  src={HookIcon} className='hook'/> : 3}
                </div>
            </div>
            <div className='desc-container'>
                <div className={index === 0 ? 'descHighLight':'desc'}>上传旧家电照片</div>
                <div className={index === 1 ? 'descHighLight':'desc'}>领取「数字藏品」</div>
                <div className={index === 2 ? 'descHighLight':'desc'}>获得618优惠</div>
            </div>
        </div>
    )
}