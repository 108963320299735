import "./home.scss";
import {useNavigate} from "react-router";
import {useContext, useState, useEffect} from "react";
import { AuthContext, serverAddress2 } from "../../App";
import MenuIcon from "../../components/menuIcon/menuIcon";
import NftSwiper from "../../components/nftSwiper/nftSwiper";
import midea1 from '../../assets/midea/midea-nft-images/midea1.jpg'
import midea2 from '../../assets/midea/midea-nft-images/midea2.jpg'
import midea3 from '../../assets/midea/midea-nft-images/midea3.jpg'
import midea4 from '../../assets/midea/midea-nft-images/midea4.jpg'
import midea5 from '../../assets/midea/midea-nft-images/midea5.jpg'
import PhotoDesc from '../../components/photoDesc/PhotoDesc'
import Button from '../../components/button/button'

export default function Home(){
    let navigate = useNavigate();
    const { state } = useContext(AuthContext);

    const [currentIndex,setIndex] = useState(0);
    const [buttonText,setButtonText] = useState('参加活动');
    const [userStatus,setUserStatus] = useState(null);
    const handleSlideChange = (e) => {
        let index = e.realIndex;
        setIndex(index);
    }

    const claim = () => {
        if(state.isLogin){
            if (!userStatus?.hasCollect&&!userStatus?.hasSkip&&!userStatus?.hasUploadStory){
                navigate('/upload')
            }
            if (userStatus?.hasSkip||userStatus?.hasUploadStory) {
                navigate('/mediaClaim')
            }
            if (userStatus.hasCollect) {
                navigate('/list')
            }
        }else{
            navigate('/login/home');
        }
    }

    useEffect(()=>{
        if(state.accessToken && state.accessToken.length > 0){
            try {
                fetch(
                    serverAddress2 + "/eventStatus",
                    {
                        method: "GET",
                        headers: {"accessToken": state.accessToken}
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === '20000'){
                            if (result.data.hasCollect) {
                                setButtonText('查看我的数字藏品')
                            }
                            setUserStatus(result.data);
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    },[state.accessToken])

    const nftInfo = [
        {
            id: '01/05',
            title: '「 遥遥领鲜者 」',
            image: midea1,
            desc: `在家也有新奇遇
做个遥遥领「鲜」者`
        },{
            id: '02/05',
            title: '「 舒适星期日 」',
            image: midea2,
            desc: `舒适悠长星期日
偷瞄一下屋内好风光`
        },{
            id: '03/05',
            title: '「 双子领航者 」',
            image: midea3,
            desc: `全季候时刻焕新
畅享清新好时光`
        },{
            id: '04/05',
            title: '「 凉风尊享1号 」',
            image: midea4,
            desc: `在家里尊享自在
推开窗去漫游星际`
        },{
            id: '05/05',
            title: '「 这夏的确凉 」',
            image: midea5,
            desc: `让微微凉意吹散热浪
啊~这夏的确凉~`
        }
    ]

    const bottomText = '本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。'

    return(
        <div className='home-page'>
            <MenuIcon/>
            <div className='home-page-content'>
                <div className="nft-swiper-title">{nftInfo[currentIndex].title}</div>
                <div className='nft-title-background'></div>
                <div className="nft-id">#{nftInfo[currentIndex].id}</div>
                <NftSwiper nftInfo={nftInfo} handleSlideChange={handleSlideChange} loopDisable={true}/>
                <PhotoDesc desc={nftInfo[currentIndex].desc} type={'2'}/>
                <Button text={buttonText} clickEvent={claim}/>
                <div className='click-icon'></div>
                <div className='three-dots'></div>
                <div className='ornament1'></div>
                <div className='ornament2'></div>
                <div className='home-title-1'></div>
                <div className='info-container-border-large'>
                    <div className='info-text'>数字藏品是元宇宙中唯一、不可拆分、可确权的数字资产。 数字藏品基于前沿科技区块链技术，具有强烈的未来科技感。 数字藏品是数字经济蓬勃发展的产物， 也是元宇宙时代构建相融合的现实与虚拟世界的基石， 富有科技感、未来感、前沿性。 在数字时代长大的Z世代是“数字原住民”， 将数字藏品视为价值存储的数字资产。 未来，意识到数字身份和数字所有权的人越来越多， 数字藏品将成为改变互联网构想和交换价值的全新方式。</div>
                </div>
                <div className='home-title-2'></div>
                <div className='info-container-border'>
                    <div className='info-text'>美的空调在618发起#以旧换新从新开始#活动主题，让用户焕新生活，从焕新空气开始。用户可以上传家中旧家电的照片，并配以不少于50字与旧家电的故事，即可获赠NFT限量数字藏品一幅及额外的50元优惠券。数字藏品领取后可分享到各大社交平台，邀请好友助力，赢取限量100名原价回收旧家电的名额。</div>
                </div>
            </div>
            <div className="bottom-text">{bottomText}</div>
        </div>
    )
}