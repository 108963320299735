import "./nftlist.scss"
import DutyFree from "../../components/DutyFree";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {AuthContext, serverAddress} from "../../App";
import {responseCode} from "../../config";
import Frame from "../../components/frame/frame";
import nftImage from './assets/nft.jpg';
import NftBorder from '../../assets/midea/nft-border.png'
import MenuIcon from "../../components/menuIcon/menuIcon";

export default function NFTlist(props){
    const { style, AddFrame, CardFrame } = props;
    const [list, setList] = useState([]);
    const {state} = useContext(AuthContext);
    let navigate = useNavigate();
    const goClaim = () => {
        navigate('/claim')
    }

    useEffect(() => {
        if(state.accessToken && state.accessToken.length > 0){
            try {
                fetch(
                    serverAddress + "/myNFTs?site=midea&pageSize=100&pageNum=1",
                    {
                        method: "GET",
                        headers: {"accessToken": state.accessToken},
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            const nfts = result.data.nfts;
                            setList(nfts);
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    },[state.accessToken])

    return(
        <div id="list-container">
            <MenuIcon/>
            <div id="content-container">
                {
                     list.map((nft,index) => {
                        return (
                            <Frame height={style.frame.height} width={style.frame.width}
                                   contentWidth={style.frame.content.width} contentHeight={style.frame.content.height}
                                   backgroundImage={NftBorder} contentImage={nft.displayURL || nftImage}
                                   nftName={nft.name || '数字藏品名字' } nftTokenID={nft.id || '123'} key={index}/>
                        )
                     })
                }
                 {/*<Frame height={style.frame.height} width={style.frame.width}*/}
                 {/*      contentWidth={style.addFrame.content.height} contentHeight={style.addFrame.content.width}*/}
                 {/*      backgroundImage={NftBorder} contentImage={AddFrame} onClick={goClaim}*/}
                 {/*      nftName={'添加数字藏品'}/>*/}
            </div>
            <div className='duty-container'>
                <DutyFree />
            </div>
        </div>
    )
}